import WebServiceRequest from "./WebServiceRequest";

class BlogCategoryDeleteRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
  }
  setId(id) {
    super.setTag("BlogCategory/DeleteBy?BlogCategoryId=" + id);
  }
}
class BlogCategoryGetAllRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BlogCategory/GetAll");
  }
}
class BlogCategoryGetRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
  }
  setId(id) {
    super.setTag("BlogCategory/GetBy?id=" + id);
  }
}
class BlogCategoryUpdateRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BlogCategory/Update");
  }
  setParams(data) {
    super.setRequestParamDataObj(data);
  }
}
class BlogCommentDeleteRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
  }
  setId(id) {
    super.setTag("BlogComment/DeleteBlogComment?BlogCommentId=" + id);
  }
}
class BlogCommentGetAllByBlogIdRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
  }
  setId(id) {
    super.setTag("BlogComment/GetAllByBlogId?blogId=" + id);
  }
}
class BlogCommentGetAllRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BlogComment/GetAll");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}
class BlogCommentGetByIdRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
  }
  setId(id) {
    super.setTag("BlogComment/GetById?blogCommentId=" + id);
  }
}
class BlogCommentUpdateRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BlogComment/UpdateBlogComment");
  }
  setParams(data) {
    super.setRequestParamDataObj(data);
  }
}
class BlogCreateBlogRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Blog/Create");
  }
  setParams(data) {
    super.setRequestParamDataObj(data);
  }
}
class BlogDeleteRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
  }
  setId(id) {
    super.setTag("Blog/DeleteBlog?BlogId=" + id);
  }
}
class BlogGetByIdRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
  }
  setId(id) {
    super.setTag("Blog/GetById?id=" + id);
  }
}
class BlogUpdateBlogRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Blog/UpdateBlog");
  }
  setParams(data) {
    super.setRequestParamDataObj(data);
  }
}
class CreateBlogCommentRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BlogComment/CreateBlogComment");
  }
  setBlogId(blogId) {
    super.setRequestParam("blogId", blogId);
  }
  setMessage(message) {
    super.setRequestParam("message", message);
  }
  setParentId(parentId) {
    super.setRequestParam("parentId", parentId);
  }
}
class BlogCategoryCreateRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BlogCategory/Create");
  }
  setParams(data) {
    super.setRequestParamDataObj(data);
  }
}
class BlogGetAllRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Blog/GetAll");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}
export {
  BlogCategoryDeleteRequest,
  BlogCategoryGetAllRequest,
  BlogCategoryGetRequest,
  BlogCategoryUpdateRequest,
  BlogCommentDeleteRequest,
  BlogCommentGetAllByBlogIdRequest,
  BlogCommentGetAllRequest,
  BlogCommentGetByIdRequest,
  BlogCommentUpdateRequest,
  BlogCreateBlogRequest,
  BlogDeleteRequest,
  BlogGetAllRequest,
  BlogGetByIdRequest,
  BlogUpdateBlogRequest,
  CreateBlogCommentRequest,
  BlogCategoryCreateRequest,
};
